import React, { useState, useEffect, useRef } from 'react';
import { UserPlus } from 'lucide-react';

const ParticleBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    const colors = [
      'rgba(255, 255, 255, 0.7)',  // White with transparency
      'rgba(240, 240, 240, 0.6)',  // Off-white
      'rgba(42, 157, 143, 0.8)',   // Teal (#2a9d8f)
      'rgba(38, 141, 129, 0.7)',   // Darker teal
      'rgba(100, 255, 218, 0.8)',  // Light teal (#64ffda)
      'rgba(72, 202, 228, 0.7)',   // Blue-green
      'rgba(0, 128, 128, 0.6)',    // Dark teal
      'rgba(46, 204, 113, 0.7)',   // Green
    ];

    class Particle {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 2 + 1;
        this.speedX = Math.random() * 0.2 - 0.05;
        this.speedY = Math.random() * 0.2 - 0.05;
        this.color = colors[Math.floor(Math.random() * colors.length)];
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        if (this.x < 0 || this.x > canvas.width) this.speedX *= -1;
        if (this.y < 0 || this.y > canvas.height) this.speedY *= -1;
      }

      draw() {
        ctx.fillStyle = this.color;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
      }
    }

    const particleArray = Array(20).fill().map(() => new Particle());

    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particleArray.forEach(particle => {
        particle.update();
        particle.draw();
      });
      animationFrameId = requestAnimationFrame(animate);
    };
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />;
};

const ComingSoonPage = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const subject = encodeURIComponent("New Waitlist Signup");
    const body = encodeURIComponent(`New signup for Terragrit waitlist: ${email}`);
    
    const mailtoLink = `mailto:waitlist@terragrit.com?subject=${subject}&body=${body}`;
    
    window.location.href = mailtoLink;
    
    setEmail('');
    alert('Thanks for joining the waitlist! An email draft has been created in your default email client.');
  };

  return (
    <div style={{
      position: 'relative',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#0a1921',
      color: 'white',
      padding: '1rem',
      overflow: 'hidden',
    }}>
      <ParticleBackground />

      {/* Brushstroke effect */}
      <div style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: '50%',
        height: '100%',
        background: 'radial-gradient(ellipse at top right, #1a3a4a 0%, transparent 70%)',
        opacity: 0.4,
      }} />

      <div style={{ position: 'relative', zIndex: 10, textAlign: 'center', maxWidth: '32rem' }}>
        <img 
          src="/final-logo.png" 
          alt="Terragrit Logo" 
          style={{ 
            width: '100%', 
            maxWidth: '400px', 
            height: 'auto', 
            marginBottom: '2rem' 
          }} 
        />
        
        <p style={{ fontSize: '1.125rem', marginBottom: '2rem', color: '#ffffff', lineHeight: '1.5' }}>
          Terragrit is the first of its kind virtualization engine for physical environments. Join the waitlist today for access.
        </p>

        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '2rem' }}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              padding: '0.5rem 1rem',
              borderTopLeftRadius: '0.25rem',
              borderBottomLeftRadius: '0.25rem',
              backgroundColor: '#1c2f3a',
              color: 'white',
              width: '100%',
              marginBottom: '0.5rem',
              border: '1px solid #2a9d8f',
              outline: 'none'
            }}
            required
          />
          <button 
            type="submit" 
            style={{
              backgroundColor: '#2a9d8f',
              color: 'white',
              padding: '0.5rem 1rem',
              borderTopRightRadius: '0.25rem',
              borderBottomRightRadius: '0.25rem',
              display: 'flex',
              alignItems: 'center',
              transition: 'background-color 0.3s, color 0.3s',
              cursor: 'pointer',
              border: 'none',
            }}
            onMouseOver={(e) => {
              e.currentTarget.style.backgroundColor = '#64ffda';
              e.currentTarget.style.color = '#0a1921';
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.backgroundColor = '#2a9d8f';
              e.currentTarget.style.color = 'white';
            }}
          >
            <UserPlus style={{ marginRight: '0.5rem' }} size={20} />
            Join the Waitlist
          </button>
        </form>

        <p style={{ fontSize: '0.875rem', opacity: 0.75, color: '#ffffff' }}>© 2025 Terragrit. All rights reserved.</p>
      </div>
    </div>
  );
};

export default ComingSoonPage;
